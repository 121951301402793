.avatar {
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  font-size: 20px !important;
  font-weight: 600;
}

.avatar-bg-A {
  background: #605458 !important;
}

.avatar-bg-B {
  background: #4e3140 !important;
}

.avatar-bg-C {
  background: #3a3b52 !important;
}

.avatar-bg-D {
  background: #1f5645 !important;
}

.avatar-bg-E {
  background: #5d8d2c !important;
}

.avatar-bg-F {
  background: #7c0d0d !important;
}

.avatar-bg-G {
  background: #705e39 !important;
}

.avatar-bg-H {
  background: #463087 !important;
}

.avatar-bg-I {
  background: #9f1e99 !important;
}

.avatar-bg-J {
  background: #ff7b25 !important;
}

.avatar-bg-K {
  background: #ef7910 !important;
}

.avatar-bg-L {
  background: #7d895c !important;
}

.avatar-bg-M {
  background: #4fb180 !important;
}

.avatar-bg-N {
  background: #099dd9 !important;
}

.avatar-bg-O {
  background: #80f !important;
}

.avatar-bg-P {
  background: #057845 !important;
}

.avatar-bg-Q {
  background: #000 !important;
}

.avatar-bg-R {
  background: #5c4c3d !important;
}

.avatar-bg-S {
  background: #4f3578 !important;
}

.avatar-bg-T {
  background: #3f5241 !important;
}

.avatar-bg-U {
  background: #8589ad !important;
}

.avatar-bg-V {
  background: #ad0b0b !important;
}

.avatar-bg-W {
  background: #40d189 !important;
}

.avatar-bg-X {
  background: #b55489 !important;
}

.avatar-bg-Y {
  background: #8d8a08 !important;
}

.avatar-bg-Z {
  background: #0db5a7 !important;
}
