/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "bootstrap/scss/bootstrap";

//@import "~bootstrap/dist/css/bootstrap.css";
@import "@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss";
@import "@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss";
// Theme customization
@import "theme/theme";

@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");
@import "theme/scss/theme";
@import "theme/scss/general";
@import "theme/scss/typography";
@import "theme/scss/buttons";
@import "theme/scss/form";
@import "theme/scss/avatars";

$primary: #673ab7;
$blue: #0073dd;
$indigo: #6610f2;
$purple: #673ab7;
$pink: #e83e8c;
$red: rgb(224, 224, 224);
$orange: #fd7e14;
$yellow: rgb(234, 182, 62);
$green: rgb(71, 154, 95);
$teal: #20c997;
$cyan: #17a2b8;
$grey: rgb(224, 224, 224);

#toast-container > div {
  opacity: 1;
}
