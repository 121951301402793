.cb-input {
  height: 30px;
  border-radius: 5px;
  border: solid 1px #d6dce2;
  background-color: #ffffff;
  line-height: 1;
  font-size: 12px;
  font-weight: normal;
  color: #415366;

  &:hover,
  &:focus {
    border-color: #d6dce2;
    outline: 0;
    box-shadow: none;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9aa7b4;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #9aa7b4;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #9aa7b4;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #9aa7b4;
  }

  &::placeholder {
    color: #9aa7b4;
  }
  &.isEmpty {
    background-color: #f4f5f6;
  }
}
.checkbox-1 {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  padding-left: 20px;
  line-height: 1;
  font-size: 14px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  input:checked ~ .checkmark {
    background-color: #f8b341;
    border: 1px solid #f8b341;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    border-color: #1f2833;
  }

  .checkmark:after {
    left: 3px;
    top: 0px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0px 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.radio-1 {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  padding-left: 4px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    border: solid 1px #415366;
    box-sizing: border-box;
  }

  input:checked ~ .checkmark {
    background-color: #fff;
    border: solid 1px #4db7c6;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background-color: #4db7c6;
  }
}
.checkbox-2 {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  padding-left: 24px;
  line-height: 1;
  font-size: 14px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  input:checked ~ .checkmark {
    background-color: #f8b341;
    border: 1px solid #f8b341;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    border-color: #fff;
  }

  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0px 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.checkbox-3 {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  padding-left: 24px;
  line-height: 1;
  font-size: 14px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  input:checked ~ .checkmark {
    background-color: #3cae4c;
    border: 1px solid #3cae4c;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    border-color: #fff;
  }

  .checkmark:after {
    left: 4px;
    top: 1px;
    width: 6px;
    height: 9px;
    border: solid white;
    border-width: 0px 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.checkbox-4 {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  padding-left: 24px;
  line-height: 1;
  font-size: 14px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  input:checked ~ .checkmark {
    background-color: #3cae4c;
    border: 1px solid #3cae4c;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    border-color: #fff;
  }

  .checkmark:after {
    left: 3px;
    top: 6px;
    width: 8px;
    border: solid white;
    border-width: 0px 2px 2px 0;
  }
}
.default-checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  padding-left: 24px;
  line-height: 1;
  font-size: 14px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  input:checked ~ .checkmark {
    background-color: #3cae4c;
    border: 1px solid #3cae4c;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
    border-color: #fff;
  }
}
.cb-textarea {
  width: 360px;
  height: 120px;
  border-radius: 5px;
  border: solid 1px #d6dce2;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1f2833;
}
.cb-time {
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  i {
    position: absolute;
    top: 9px;
    left: 12px;
    z-index: 0;
    color: rgb(76, 183, 198);
  }
  & > input.time_input {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 2;
    padding-left: 30px;
    background: none;
    cursor: pointer;
  }
}
