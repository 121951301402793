$white: #fff;
$black: #000;
$text-black-1: #415366;
$text-black-2: #1f2833;
$text-black-3: #1d232f;
$text-gray: #6b7e93;
$text-cyan: #4db7c6;
$page-bg: rgba(107, 126, 147, 0.05);
$yellow: #ffba00;
$red: #ff6468;
$gray: #6b7e93;
$maroon: rgb(128, 0, 0);
$green: #22ae37;
$yellow2: #ffd23b;

.text-black-1 {
  color: $text-black-1;
  margin-left: -0.5em;
}

.text-black-0 {
  color: $text-black-1;
}

.text-black-2 {
  color: $text-black-2;
}

.text-black-3 {
  color: $text-black-3;
}

.text-cyan {
  color: $text-cyan;
}
.text-cyan-force {
  color: $text-cyan !important;
}
.text-gray {
  color: $text-gray;
}
.text-white {
  color: $white;
}
.cb-page-bg-color {
  background-color: $page-bg;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-yellow-2 {
  background-color: $yellow2;
}

.bg-red {
  background-color: $red;
}

.bg-maroon {
  background-color: $maroon;
}

.bg-gray {
  background-color: $gray;
}

//Custom : defined css
.error-danger {
  color: red;
}
.bg-green {
  background-color: $green;
}
.success-msg {
  color: green;
}

.bg-gray {
  background-color: $text-gray;
}
.best-choice {
  height: 58px;
  width: 59%;
  background-color: #f3ffff;
  border-bottom: 1px solid #dbdbdb;
  padding: 8px 12px 8px 12px;
}
.best-choice-text {
  font-size: 14px;
  font-weight: 400;
  color: black;
}
