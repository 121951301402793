html,
body,
* {
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  border: 0;
  // color: $text-black-2;
}
.cb-container {
  min-height: 100vh;
  @media (min-width: 992px) {
    padding: 50px 0 0 200px;
  }
}
.header-padding {
  padding: 40px 40px 32px 40px;
}
.w-140 {
  width: 140px !important;
}
.w-160 {
  width: 160px;
}
.w-640 {
  width: 640px;
  @media (max-width: 768px) {
    width: auto;
  }
}
.w-740 {
  width: 740px;
}
.w-415 {
  width: 415px;
}
.w-100 {
  width: 100px !important;
}
.w-80 {
  width: 80px !important;
}
.h-500 {
  height: 500px;
}
.h-250 {
  height: 250px;
}
.h-70 {
  height: 70px;
}
.max-w-280 {
  max-width: 280px;
}
.max-w-300 {
  max-width: 300px;
}
.max-w-380 {
  max-width: 380px;
}
.p-32 {
  padding: 32px !important;
}
.cb-accordian {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  max-height: 60px;
  overflow: hidden;

  &.expand {
    max-height: 5000px;
  }

  .acc-header {
    height: 60px;
    padding: 0;
    background-color: #fff;
    position: relative;

    h5 {
      margin: 22px 0 0 20px;
    }
    .btn-acc-toggle {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(107, 126, 147, 0.2);
      border: none;

      &:focus {
        border: none;
        outline: none;
      }

      i {
        font-size: 12px;
        position: relative;
        top: -1px;
      }
    }
  }
  .acc-body {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
  }
}
.cb-table-1 {
  width: 100%;
  thead {
    td {
      padding: 8px 0 0 0;
    }
  }
  tbody {
    td {
      padding: 16px 0;
    }
  }
  margin-top: 0rem !important;
  margin-right: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.w-240 {
  width: 240px;
}
.w-260 {
  width: 260px;
}
.w-100p {
  width: 100%;
}
.max-w-840 {
  max-width: 840px;
}
.max-w-400 {
  max-width: 400px;
}
.scheduler-modal {
  width: 400px;
  margin: -24px;
}
.ml-30 {
  margin-left: 30px;
}
.onboarding-main {
  margin-bottom: 150px;
}
.coming-soon-txt {
  font-size: 12px;
  color: #4db7c6;
}
.m-auto {
  margin: auto;
}
.cb-accordian-yellow {
  border-radius: 5px;
  background-color: #fff;
  max-height: 60px;
  overflow: hidden;

  &.expand {
    max-height: 5000px;
  }

  .acc-header {
    height: 60px;
    padding: 0;
    background-color: #ffd23b;
    position: relative;

    h5 {
      font-weight: bold;
      text-align: left;
      color: #1f2833;
      white-space: nowrap;
    }
  }

  h5 {
    margin: 22px 0 0 30px;
  }

  .btn-acc-toggle {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: rgba(107, 126, 147, 0.2);
    border: none;

    &:focus {
      border: none;
      outline: none;
    }

    i {
      font-size: 12px;
      position: relative;
      top: -1px;
    }
  }
}
.table-2 {
  thead tr {
    border-bottom: 1px solid #e6e6e6;
  }
  td:first-child h4 {
    padding-left: 30px !important;
  }
  tbody {
    tr:nth-child(even) {
      background-color: rgba(248, 179, 65, 0.1);
    }
  }
  td:first-child {
    border-radius: 5px 0 0 5px;
  }
  td:last-child {
    border-radius: 0 5px 5px 0;
  }
}
.cb-tooltip-btn {
  cursor: pointer;
  color: $text-gray;
  &:hover {
    color: $text-cyan;
  }
}
.cb-tooltip {
  .tooltip-inner {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #4db7c6;
    background-color: #ffffff;
    font-size: 12px;
    text-align: left;
    color: #1f2833;
  }
  .arrow::before {
    border-bottom-color: #4db7c6;
  }
}
.onboarding-footer {
  height: 70px;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px 30px 0 0;
}
.onboarding-header {
  width: 100%;
  background-color: rgba(77, 183, 198, 0.2);
  margin-top: 50px;

  ul {
    margin: 0;
    width: 100%;
    display: flex;
    height: 50px;
    padding: 0 40px;
    justify-content: space-between;
    list-style: none;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      min-width: 150px;
      color: #4db7c6;
      font-size: 12px;
      cursor: pointer;

      span {
        width: 22px;
        height: 22px;
        display: block;
        border: 2px solid #4db7c6;
        border-radius: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        padding-top: 3px;
        margin-right: 10px;
        color: #4db7c6;
      }

      &.active {
        color: #1f2833;
        font-weight: bold;
        border-bottom: 2px solid;

        span {
          border: solid 1.5px #1f2833;
          color: #1f2833;
        }
      }
      &.mobile-steps {
        display: none;
        color: #1f2833;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 1px;
        i {
          font-size: 14px;
          font-weight: bold;
          line-height: 1;
          font-style: normal;
        }
      }
    }
    @media (max-width: 992px) {
      li:not(.active) {
        display: none;
      }
      li.mobile-steps {
        display: flex;
      }
    }
  }
}
.border-right-step-6 {
  border-right: 1px solid rgba(107, 126, 147, 0.26);
  @media (max-width: 992px) {
    border-bottom: 1px solid rgba(107, 126, 147, 0.26);
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-right: none;
  }
}
.height-100vh {
  height: 100vh;
}
.setting-menu {
  max-width: 240px;
  height: 160vh;
  min-width: 240px;
  border-right: 2px solid rgba(221, 221, 221, 0.68);
  padding: 30px 20px;

  .btn-conf-wizard {
    width: 100%;
    height: 40px;
  }

  ul {
    list-style: none;
    margin: 30px 0 0 0;
    padding: 0;
    li {
      list-style-type: none;
      list-style: none;
      margin: 0;
      padding: 0;
      a {
        font-size: 14px;
        text-decoration: none;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $text-black-1;
        height: 40px;
        border-radius: 5px;
        display: block;
        padding: 10px 20px;

        &.active {
          color: #00667e;
          font-weight: bold;
          background-color: #dbf1f4;
        }
      }
    }
  }
  @media (max-width: 991px) {
    width: 100vw;
    top: 0;
    z-index: 1001;
    height: 100vh;
    max-width: 100vw;
    position: fixed;
    background-color: #f7f8fa;

    & > div.setting-menu-wrapper {
      max-width: 240px;
    }
    ul li a {
      width: 100%;
      height: 40px;
      padding-top: 8px;
    }
  }
  .btn-back {
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #4db7c6;
  }
  .close-setting-menu {
    width: 30px;
    height: 30px;
    background-color: rgba(107, 126, 147, 0.2);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.setting-actions {
  border-bottom: 2px solid rgba(221, 221, 221, 0.68);
  padding: 0 32px;
}
.profile-upload {
  display: flex;
  img {
    width: 80px;
    height: 80px;
    margin-right: 24px;
    border-radius: 100%;
  }
  div a {
    font-family: Lato;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $text-cyan;
    display: block;
    margin-top: 16px;

    &:first-child {
      margin-top: 12px;
    }
  }
}
.profile-form {
  padding-left: 16px;
  padding-top: 40px;
  max-width: 664px;
}
.password-form-border {
  border-left: 2px solid rgba(221, 221, 221, 0.68);
}
.holiday-selector {
  border-radius: 5px;
  border: solid 1px #d6dce2;
  background-color: #ffffff;
  height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 15px 24px;
}
//Css for new event page
.single-event-acc {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  max-height: 60px;
  overflow: hidden;
  transition: all 0.25s ease-in;
  &.expand {
    max-height: 100%;
  }

  .acc-header {
    height: 60px;
    padding: 0;
    background-color: #fff;
    position: relative;

    &:hover .participations {
      width: 160px;
    }

    .participations {
      position: absolute;
      top: 0;
      left: 0;
      height: 60px;
      width: 4px;
      text-align: right;
      overflow: hidden;
      transition: all 0.5s ease;

      h5 {
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        font-weight: bold;
        text-align: left;
        color: #ffffff;
        white-space: nowrap;
      }
    }

    h5 {
      margin: 22px 0 0 20px;
    }

    .btn-acc-toggle {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-color: rgba(107, 126, 147, 0.2);
      border: none;

      &:focus {
        border: none;
        outline: none;
      }

      i {
        font-size: 12px;
        position: relative;
        top: -1px;
      }
    }
  }
  .acc-body {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    .event-details {
      h4 {
        opacity: 0.4;
      }
    }
    .event-more-details {
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      overflow: hidden;
      display: none;

      &.expand {
        display: block;
      }

      h4 {
        opacity: 0.4;
      }
      textarea {
        font-size: 14px;
        height: 50px;
      }
      .delete-url {
        color: #e84822;
        cursor: pointer;
        padding-top: 33px;
      }
      .add-url {
        cursor: pointer;
      }
    }
    .event-view-more {
      height: 40px;
      background-color: #dbf1f4;

      span {
        font-size: 14px;
        cursor: pointer;
        padding: 10px 0;
        display: inline-block;
      }
    }
  }
}
.add-admin.modal .modal-dialog {
  width: calc(100vw - 510px);
  max-width: calc(100vw - 510px);
  margin-left: 470px;
  margin-top: 152px;

  @media (max-width: 1190px) {
    width: 90vw;
    max-width: 90vw;
    margin: 5vh 5vw;
  }
  .table_wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.search-people {
  input {
    padding-right: 30px;
  }
  .search-icon {
    position: absolute;
    top: 7px;
    right: 8px;
    z-index: 4;
    color: rgba(130, 130, 130, 0.6);
  }
}
.table_footer {
  height: 60px;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 0 -16px;
  display: flex;
  align-items: center;
}
.scroll-view {
  height: calc(100vh - 120px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px;
}
.cursor-pointer {
  cursor: pointer;
}
// .cdk-overlay-pane {
//   max-height: 100% !important;
//   overflow: scroll;
//   margin: 100px 0;
//   display: block;
//   height: 80vh;
// }

// Documents Manager Table css
.dm-table {
  width: 100%;
  tr {
    background: #ffffff;
  }
  th {
    column-width: 228px;
  }
}

.dm-user-icon {
  height: 18px;
  width: 18px;
  margin-left: 10px;
  margin-right: 5px;
}

.dm-invite-button {
  height: 37px;
  width: 139px;
  padding: 6px 12px;
  gap: 10px;
  background: #2aa0b0;
  border-radius: 4px;
}

.empty-doc-list-image {
  margin: auto;
  text-align: center;
  width: 23%;
  margin-top: 180px;
  display: block;
}

.text-empty-doc-list {
  margin: auto;
  margin-top: 1em;
  font-size: 25px;
  text-align: center;
}

.sub-text-empty-doc-list {
  font-size: 18px;
  text-align: center;
}

// .dm-text-1 {
//   font-style: normal;
//   font-weight: 400;
//   font-size: 15px;
//   line-height: 18px;
//   display: block;
//   text-transform: lowercase;
// }

// .dm-text-1:first-letter {
//   text-transform: uppercase !important;
// }

.dm-text-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  // text-transform: capitalize;
}

.dm-text-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: block;
  // text-transform: lowercase;
}

@media screen and (min-width: 900px) and (max-width: 1400px) {
  .search-people {
    input {
      padding-right: 115px;
    }
  }
  .cb-table-1 {
    width: 50%;
    thead {
      td {
        padding: 8px 0 0 0;
      }
    }
    tbody {
      td {
        padding: 16px 0;
      }
    }
    margin-top: 0rem !important;
    margin-right: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .dm-table {
    width: 100%;
    tr {
      background: #ffffff;
    }
    th {
      column-width: 114px;
    }
  }
  .cb-container {
    padding: 50px 0 0 115px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1400px) {
  .setting-menu {
    max-width: 220px;
    height: 100vh;
    min-width: 100px;
    border-right: 2px solid rgba(221, 221, 221, 0.68);
    padding-top: 30px;
    margin-left: -30px;
    // .btn-conf-wizard {
    //   width: 100%;
    //   height: 40px;
    // }

    ul {
      list-style: none;
      margin: 20px 0 0 0;
      padding: 0;
      li {
        list-style-type: none;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
          font-size: 14px;
          text-decoration: none;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          color: $text-black-1;
          height: 40px;
          border-radius: 5px;
          display: block;
          padding: 10px 10px;

          &.active {
            color: #00667e;
            font-weight: bold;
            background-color: #dbf1f4;
            width: 112%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .sidenav {
    display: none;
  }
}
